import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useApolloClient } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

import useConsole from '../../hooks/useConsole';
import {
  SIGN_OUT,
  CLEAR_CERTIF_DOC_NOTIFICATION,
  CCDOCS_LS_SHOW_KEY,
  GET_CUSTOMER,
} from '../../shared';

import { LogoutButton } from './styled/NavUser.styled';

function NavMenuSignOutLink(props) {
  const { onSignout, tabIndex } = props;

  const history = useHistory();
  const client = useApolloClient();
  const [signOut] = useMutation(SIGN_OUT, {
    refetchQueries: [{ query: GET_CUSTOMER }],
  });
  const [clearCertifDocNotif] = useMutation(CLEAR_CERTIF_DOC_NOTIFICATION);

  const { consoleWarn } = useConsole();

  const onCustomerSignout = async () => {
    if (onSignout) onSignout();

    let res = {};

    try {
      await clearCertifDocNotif();
      res = await signOut();
    } catch (e) {
      consoleWarn(e?.message);
    } finally {
      const { data } = res || {};

      if (!data?.signOut?.errors?.length) {
        client.cache.reset();
        // Remove 'contribution confirmation document' notification flag on sign out
        window.sessionStorage.removeItem(CCDOCS_LS_SHOW_KEY);
        history.push('/login?logout=true');
      }
    }
  };

  return (
    <LogoutButton variant="outline" onClick={onCustomerSignout} tabIndex={tabIndex}>
      <Typography variant="h3">
        <FormattedMessage id="sign.out" />
      </Typography>
    </LogoutButton>
  );
}

NavMenuSignOutLink.propTypes = {
  onSignout: PropTypes.func,
  /** We are using tabIndex to make it unreachable when needed */
  tabIndex: PropTypes.number,
};

export default NavMenuSignOutLink;
