import React from 'react';
import { useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { Typography } from '@mui/material';

import { Button } from '../../../../components/ui';
import DentistInput from './DentistInput';
import { GET_DENTISTS } from '../../../../shared';
import {
  BackButton,
  FormSectionContainer,
  ButtonContainer,
  PageTitle,
} from '../../../../components/common';
import {
  DENTOLO_DENTIST_ID,
  DENTOLO_DENTIST_NAME,
  DENTOLO_PARTNER_DENTIST,
  DENTOLO_TREATMENT_CATEGORY,
  useNewDentoloFormDataValue,
} from '../../claimFormData/newClaimFormData';
import {
  useDentistDetailsBackButtonLink,
  useDentistDetailsNextButtonLink,
} from './dentistDetailsHooks';
import { createSelectOptions } from './helpers';

const DentistDetails = () => {
  const history = useHistory();
  const { claimId } = useParams();

  const [dentistId, setDentistId] = useNewDentoloFormDataValue(DENTOLO_DENTIST_ID);
  const [dentistName, setDentistName] = useNewDentoloFormDataValue(DENTOLO_DENTIST_NAME);
  const [, /* isDentoloDentist */ setIsDentoloDentist] =
    useNewDentoloFormDataValue(DENTOLO_PARTNER_DENTIST);
  const [treatmentCategory] = useNewDentoloFormDataValue(DENTOLO_TREATMENT_CATEGORY);

  const backButtonLink = useDentistDetailsBackButtonLink(claimId);
  const nextButtonLink = useDentistDetailsNextButtonLink(claimId, treatmentCategory);

  const { data: dentistsData } = useQuery(GET_DENTISTS);

  const selectOptions = createSelectOptions(dentistsData?.dentists);

  const selectedOptionFromList = dentistId
    ? selectOptions.find((option) => option?.value === dentistId)
    : null;
  const selectedOption =
    !dentistId && !!dentistName
      ? { label: dentistName, value: dentistName, __isNew__: true }
      : selectedOptionFromList;

  return (
    <>
      <BackButton to={backButtonLink}>
        <Typography variant="h3">
          <FormattedMessage id="common.button.back" />
        </Typography>
      </BackButton>

      <PageTitle>
        <Typography variant="h1">
          <FormattedMessage id="dentolo.new_claim.dentist_details.page_title" />
        </Typography>
      </PageTitle>

      <FormSectionContainer data-testid="select_dentist">
        <DentistInput
          selectOptions={selectOptions}
          selectedOption={selectedOption}
          setDentistId={setDentistId}
          setDentistName={setDentistName}
          setIsDentoloDentist={setIsDentoloDentist}
        />
      </FormSectionContainer>

      <ButtonContainer>
        <Button
          data-testid="next_button"
          variant="primary"
          onClick={() => history.push(nextButtonLink)}
          disabled={!dentistName}
        >
          <Typography variant="buttons">
            <FormattedMessage id="common.button.next" />
          </Typography>
        </Button>
      </ButtonContainer>
    </>
  );
};

export default DentistDetails;
