import React, { useState, useEffect, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { CSSTransition } from 'react-transition-group';
import { Typography } from '@mui/material';

import { GET_CCDOCUMENTS, imgLinks } from '../../shared';

import DownloadContributionConfirmation from './DownloadContributionConfirmation';
import { Container, PageTitle } from '../common';
import { ErrorPlaceholder, Paragraph } from '../ui';
import {
  PaymentOverviewHelpCenterLink,
  PaymentOverviewHelpContent,
  StyledDownloadContributionConfirmationWrapper,
} from './PaymentOverview.styled';

const heplCenterUrl = 'https://www.dentolo.de/ratgeber/zahnzusatzversicherung-steuererklaerung/';

function PaymentOverview() {
  const {
    data: ccdocsData,
    loading: ccdocsLoading,
    error: ccdocsError,
  } = useQuery(GET_CCDOCUMENTS);

  const [ccdocs, setCcdocs] = useState([]);

  const openNewTab = useCallback(
    (url) => (event) => {
      event.preventDefault();
      window.open(url);
    },
    []
  );

  useEffect(() => {
    if (!ccdocsLoading && ccdocsData) {
      setCcdocs(ccdocsData?.contributionConfirmationsDocuments);
    }
  }, [ccdocsData, ccdocsLoading]);

  if (ccdocsError) return <ErrorPlaceholder />;

  return (
    <CSSTransition in={ccdocs.length >= 0} timeout={400} classNames="slow-fade" unmountOnExit>
      <Container>
        <PageTitle>
          <Typography variant="h1">
            <FormattedMessage id="payment.overview.heading" />
          </Typography>
        </PageTitle>

        <Paragraph>
          <Typography variant="p">
            <FormattedMessage id="payment.overview.download.certificate" />
          </Typography>
        </Paragraph>

        <StyledDownloadContributionConfirmationWrapper hasContribution={ccdocs?.length > 0}>
          {ccdocs?.length > 0 ? (
            ccdocs?.map((confirmation) => (
              <DownloadContributionConfirmation
                key={confirmation?.id}
                year={confirmation?.year}
                url={confirmation?.url}
                openNewTab={openNewTab}
              />
            ))
          ) : (
            <FormattedMessage id="payment.overview.message.no_contribution" />
          )}
        </StyledDownloadContributionConfirmationWrapper>

        <PaymentOverviewHelpContent hasShadow={false} filled>
          <Typography variant="h3">
            <FormattedMessage id="payment.overview.about.taxes" />
          </Typography>
          <PaymentOverviewHelpCenterLink variant="nostyle" onClick={openNewTab(heplCenterUrl)}>
            <Typography variant="p">
              <FormattedMessage id="payment.overview.helpcenter" />
            </Typography>
          </PaymentOverviewHelpCenterLink>
        </PaymentOverviewHelpContent>

        <img
          src={imgLinks['icon.payment.overview.background']}
          alt="payment overview background"
          style={{ maxWidth: '100%' }}
        />
      </Container>
    </CSSTransition>
  );
}

export default PaymentOverview;
