import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

import {
  StyledOnBoardCard,
  StyledOnBoardCardTitle,
  StyledOnBoardCardText,
} from './HomeOnboardPetolo.styled';

const OnboardCard = ({ titleHtml, bodyText, children }) => {
  return (
    <StyledOnBoardCard>
      <StyledOnBoardCardTitle dangerouslySetInnerHTML={{ __html: titleHtml || '' }} />
      {children}
      <StyledOnBoardCardText style={{ paddingTop: '8px' }}>
        <Typography variant="p">{bodyText || ``}</Typography>
      </StyledOnBoardCardText>
    </StyledOnBoardCard>
  );
};

OnboardCard.propTypes = {
  titleHtml: PropTypes.string,
  bodyText: PropTypes.string,
  children: PropTypes.node,
};

export default OnboardCard;
