import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import { Button } from '../../../components/ui';
import { Separator, Icon } from '../../../components/common';
import {
  StyledPromotionCarouselCard,
  StyledPromotionCarouselCardContentImageWrapper,
  StyledPromotionCarouselCardContentTitle,
  StyledPromotionCarouselButtonContainer,
} from '../PromotionCarouselCard.styled';

const ReferralLinkCardPetolo = () => {
  const history = useHistory();

  const handleGotoReferralPage = () => {
    history.push('/referral');
  };

  return (
    <StyledPromotionCarouselCard>
      <StyledPromotionCarouselCardContentImageWrapper>
        <Icon icon="icon.user.referral.pet.present" alt="referral link image" />
      </StyledPromotionCarouselCardContentImageWrapper>

      <StyledPromotionCarouselCardContentTitle>
        <Typography variant="h3">
          <FormattedMessage id="promotion.carousel.petolo.referral_link.title" />
        </Typography>
      </StyledPromotionCarouselCardContentTitle>

      <Separator style={{ marginTop: 0, marginBottom: 0 }} />

      <StyledPromotionCarouselButtonContainer>
        <Button onClick={handleGotoReferralPage}>
          <Typography variant="buttons">
            <FormattedMessage id="promotion.carousel.petolo.referral_link.button.label" />
          </Typography>
        </Button>
      </StyledPromotionCarouselButtonContainer>
    </StyledPromotionCarouselCard>
  );
};

export default ReferralLinkCardPetolo;
