import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Typography } from '@mui/material';

import useInsuranceInfo from '../../hooks/useInsuranceInfo';
import { useFileUploadIcon } from '../../hooks/useIcons';
import { CLAIM_UPLOAD_FLOW_SOURCE_UPLOAD_DOCUMENT } from '../../features/claims/claimUploadFlowSource';
import {
  DENTOLO_EDIT_STEP_MAIN,
  PETOLO_EDIT_STEP_MAIN,
  VITOLO_EDIT_STEP_MAIN,
} from '../../features/claims/edit/editClaimFormSteps';
import {
  claimStatus,
  claimStatusExplanation,
  formatDate,
  INSURANCE_CATEGORY_DENTAL,
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_TYPE_PETOLO,
  INSURANCE_TYPE_VITOLO,
  showMoney,
} from '../../shared';

import DownloadLink from './DownloadLink';
import { Href } from './Button';
import { Icon, Separator } from '../common';
import { StyledClaimStatus } from './styled/ClaimStatus.styled';
import {
  StyledClaimStatusInfoCard,
  StyledClaimStatusUpdatedTime,
  StyledClaimStatusDetails,
  StyledClaimStatusTitle,
  StyledUserClaimComments,
  StyledClaimDocumentsDownloadContainer,
  StyledClaimDocumentsDownloadTextTitle,
  StyledClaimDocumentsDownloadTextDescription,
  StyledHelpCenterArticleContainer,
} from './styled/ClaimDetailsInfo.styled';
import {
  StyledUploadButtonContainer,
  StyledUploadButtonLabel,
} from '../../features/claims/components/UploadDocument/FileUploader.styled';

const getUploadDocumentLink = (insuranceType, claimId) => {
  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return `${DENTOLO_EDIT_STEP_MAIN}/${claimId}?source=${CLAIM_UPLOAD_FLOW_SOURCE_UPLOAD_DOCUMENT}`;
    case INSURANCE_TYPE_PETOLO:
      return `${PETOLO_EDIT_STEP_MAIN}/${claimId}?source=${CLAIM_UPLOAD_FLOW_SOURCE_UPLOAD_DOCUMENT}`;
    case INSURANCE_TYPE_VITOLO:
      return `${VITOLO_EDIT_STEP_MAIN}/${claimId}?source=${CLAIM_UPLOAD_FLOW_SOURCE_UPLOAD_DOCUMENT}`;
    default:
      return `${DENTOLO_EDIT_STEP_MAIN}/${claimId}?source=${CLAIM_UPLOAD_FLOW_SOURCE_UPLOAD_DOCUMENT}`;
  }
};
const GetRfiText = ({ insuranceCategory, intl }) => {
  switch (insuranceCategory) {
    case INSURANCE_CATEGORY_DENTAL:
      return intl.formatMessage({ id: 'document.rfi.dentolo.text.desc' });
    default:
      return intl.formatMessage({ id: 'document.rfi.petolo.text.desc' });
  }
};

const renderAnchorTagForTerms = (chunk, url) => (
  <Href variant="nostyle" target="_blank" rel="noopener noreferrer" href={url}>
    {chunk}
  </Href>
);

const ClaimDetailsInfo = (props) => {
  const { claim, latestPayout, customer, isSameIban, maskIban } = props;

  const intl = useIntl();
  const history = useHistory();
  const { id: claimId } = useParams();
  const { category: insuranceCategory, type: insuranceType } = useInsuranceInfo();

  const isDocTreatmentPlan = claim?.treatmentPlan;
  const isReimbAcknowStatus = claim?.status === claimStatus.REIMBURSEMENT_ACKNOWLEDGED;
  const isReimbursment = isDocTreatmentPlan && isReimbAcknowStatus && claim?.lockedAmountCents > 0;

  const uploadDocumentLink = getUploadDocumentLink(insuranceType, claimId);
  const uploadDocumentCtaIcon = useFileUploadIcon();
  const informationRequestDocumentUrl = claim?.informationRequestDocumentUrl;

  const goToUploadStep = () => {
    history.push(uploadDocumentLink);
  };

  return (
    <StyledClaimStatusInfoCard>
      <StyledClaimStatusUpdatedTime>
        <Typography variant="p">
          {formatDate(claim?.updatedAt, 'DD.MM.YY HH:mm')}{' '}
          <FormattedMessage id="claim.details.general.hour" />
        </Typography>
      </StyledClaimStatusUpdatedTime>

      {claim?.statusDetails && (
        <StyledClaimStatusTitle>
          <Typography variant="h3">
            <FormattedMessage id={`claim.status.details.${claim?.statusDetails}`} />
          </Typography>
          {/* typo check */}
          {isReimbursment && (
            <StyledClaimStatus claimStatus={claim?.status}>
              <FormattedMessage id="claim.details.general.amount" tagName="span" />
              <span>: {showMoney((claim?.lockedAmountCents ?? 0) / 100)}</span>
            </StyledClaimStatus>
          )}
        </StyledClaimStatusTitle>
      )}

      {/* Important: there can be no payouts for a claim with status [WAITING_FOR_PAYOUT, CLOSED, NEW] */}
      {claimStatusExplanation[claim?.statusDetails] && (
        <>
          <StyledClaimStatusDetails>
            {claimStatusExplanation[claim?.statusDetails].statusExplanation({
              isSameIban: isSameIban(customer?.iban, latestPayout?.recipientIban),
              payoutAmount: showMoney((latestPayout?.amountCents ?? 0) / 100),
              recepientIban: maskIban(latestPayout?.recipientIban),
              recepientName: latestPayout?.recipientName,
              reimbursableAmount: showMoney((claim?.lockedAmountCents ?? 0) / 100),
              isReimbursment,
              text: '',
              insuranceType,
            })}
          </StyledClaimStatusDetails>

          {latestPayout?.helpcenterArticleUrl && (
            <StyledHelpCenterArticleContainer>
              <FormattedMessage
                id="claim.payout.helpcenter_article.text"
                values={{
                  a: (chunk) => renderAnchorTagForTerms(chunk, latestPayout.helpcenterArticleUrl),
                }}
              />
            </StyledHelpCenterArticleContainer>
          )}

          {claimStatusExplanation[claim?.statusDetails].showDownloadDocuments &&
            informationRequestDocumentUrl && (
              <StyledClaimDocumentsDownloadContainer>
                <DownloadLink
                  link={informationRequestDocumentUrl}
                  name="auskunftsersuchen"
                  key={informationRequestDocumentUrl}
                  target="_blank"
                >
                  <strong>
                    <FormattedMessage id="document.rfi.text.head" />
                  </strong>
                </DownloadLink>

                <StyledClaimDocumentsDownloadTextTitle>
                  <FormattedMessage id="document.rfi.text.title" />
                </StyledClaimDocumentsDownloadTextTitle>

                <Separator />

                <StyledClaimDocumentsDownloadTextDescription>
                  <GetRfiText insuranceCategory={insuranceCategory} intl={intl} />
                </StyledClaimDocumentsDownloadTextDescription>
              </StyledClaimDocumentsDownloadContainer>
            )}
        </>
      )}

      {isReimbursment && (
        <StyledClaimStatusDetails>
          <FormattedMessage id="claim.details.general.load.invoice" />
        </StyledClaimStatusDetails>
      )}

      {claim?.note &&
        (claimStatus.WAITING_FOR_INFORMATION === claim?.status ||
          claimStatus.DECLINED === claim?.status) && (
          <StyledUserClaimComments>{claim?.note}</StyledUserClaimComments>
        )}

      {(isReimbAcknowStatus || claimStatus.WAITING_FOR_INFORMATION === claim?.status) && (
        <>
          <Separator />

          <StyledUploadButtonContainer onClick={goToUploadStep}>
            <Icon
              icon={uploadDocumentCtaIcon}
              alt="claim upload step button"
              data-testid="btn-goto-upload-step"
              style={{ width: '3.5rem', height: '3.5rem' }}
            />

            <StyledUploadButtonLabel>
              <FormattedMessage
                id={`common.button.upload.${isReimbursment ? 'invoice' : 'document'}`}
              />
            </StyledUploadButtonLabel>
          </StyledUploadButtonContainer>
        </>
      )}
    </StyledClaimStatusInfoCard>
  );
};

export default ClaimDetailsInfo;
