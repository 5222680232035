import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import {
  StyledReferralStatusDescription,
  StyledReferralStatusDescriptionContainer,
  StyledReferralTitle,
} from './ReferralPage.styled';

function ReferralStatusDescription(props) {
  const { titleId, statusDescription } = props;

  return (
    <StyledReferralStatusDescriptionContainer>
      <StyledReferralTitle>
        <Typography variant="h3">
          <FormattedMessage id={titleId} />
        </Typography>
      </StyledReferralTitle>

      {statusDescription.map((description) => (
        <StyledReferralStatusDescription key={description?.id}>
          <Typography variant="p">{description?.formattedDescription}</Typography>
        </StyledReferralStatusDescription>
      ))}
    </StyledReferralStatusDescriptionContainer>
  );
}

export default ReferralStatusDescription;
