import { css, styled } from '@mui/material';

import { MIN_WIDTH_LG, MIN_WIDTH_MD, MIN_WIDTH_SM } from '../../../theme/breakpoints';
import { IconButton } from '../../common';

export const StyledInsureAnotherPetBannerContainer = styled('div')(
  ({ theme }) => css`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
    background-color: ${theme.palette.common.white};
    z-index: 1;
    box-shadow: ${theme.shadows[1]};

    @media (min-width: ${MIN_WIDTH_MD}) {
      height: 230px;
      box-shadow: none;
    }
  `
);

export const StyledInsureAnotherPetBanner = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  column-gap: 5rem;
  padding: 1rem;

  @media (min-width: ${MIN_WIDTH_MD}) {
    flex-direction: row;
    padding: 2rem;
  }
`;

export const StyledInsureAnotherPetBannerPetoloLogo = styled('img')`
  position: relative;
  width: 100px;
  height: auto;
  margin-bottom: 1rem;
`;

export const StyledInsureAnotherPetBannerCloseButton = styled(IconButton)`
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 24px;
  height: 24px;
  z-index: 2;

  @media (min-width: ${MIN_WIDTH_MD}) {
    right: 1.5rem;
    top: 1.5rem;
  }
`;

export const StyledInsureAnotherPetBannerPetImage = styled('img')`
  position: absolute;
  right: -3rem;
  bottom: 0.5rem;
  width: 180px;
  height: auto;

  @media (min-width: ${MIN_WIDTH_SM}) {
  }

  @media (min-width: ${MIN_WIDTH_MD}) {
    bottom: -4.5rem;
    left: 48rem;
    width: 400px;
  }

  @media (min-width: ${MIN_WIDTH_LG}) {
    left: 56rem;
  }
`;

export const StyledInsureAnotherPetBannerContent = styled('div')`
  max-width: 35rem;
  z-index: 1;
`;

export const StyledInsureAnotherPetBannerContentTitle = styled('p')(
  ({ theme }) => css`
    color: ${theme.palette.main.professional_blue};
    margin-bottom: 1rem;
    width: 70%;

    @media (min-width: ${MIN_WIDTH_MD}) {
      margin-bottom: 0.5rem;
    }
  `
);

export const StyledInsureAnotherPetBannerContentDescription = styled('p')(
  ({ theme }) => css`
    color: ${theme.palette.main.professional_blue};
    margin-bottom: 1rem;
    padding-right: 3rem;
    display: none;

    @media (min-width: ${MIN_WIDTH_MD}) {
      display: block;
    }
  `
);
