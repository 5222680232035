import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes, { oneOfType } from 'prop-types';
import { Typography } from '@mui/material';

import { invalidTreatAmountRegex } from '../../../../shared/regex';

import {
  BackButton,
  FormSectionContainer,
  ButtonContainer,
  ErrorMessage,
  PageTitle,
  Text,
} from '../../../../components/common';
import { Button } from '../../../../components/ui';
import { Input, StyledHintContainer } from './TreatmentAmount.styled';

export default function TreatmentAmount(props) {
  const {
    backButtonLink,
    nextButtonLink,
    pageTitleId,
    inputLabelId,
    maxLength,
    treatmentAmount,
    setTreatmentAmount,
    errorMessageId,
    hintTextId,
  } = props;

  const history = useHistory();

  const [isValidAmount, setIsValidAmount] = useState(true);

  const handleTreatmentAmountKeyDown = (ev) => {
    if (!ev) return;

    const regex = new RegExp(invalidTreatAmountRegex);

    if (regex.test(ev?.key)) {
      ev.preventDefault();
    }
  };

  const handleTreatmentAmountBlur = (ev) => {
    if (!ev) return;

    // let value = ev?.target?.value.replace('-', '');
    let value = ev?.target?.value;

    if (parseInt(value, 10) < 1 || Number.isNaN(Number(value))) {
      setIsValidAmount(false);
      setTreatmentAmount('');
    } else if (value) {
      value = parseFloat(value).toFixed(2);
      value += /^\d+\.\d{1}$/gi.test(value) ? '0' : '';

      setIsValidAmount(true);
      setTreatmentAmount(value);
    }
  };

  const handleTreatmentAmountChange = (ev) => {
    if (!ev) return;

    const value = ev?.target?.value;
    const splitValueWithDecimal = value?.split('.');

    if (splitValueWithDecimal[0]?.length > maxLength) return;

    setIsValidAmount(true);
    setTreatmentAmount(ev?.target?.value);
  };

  const isFormValid = !!treatmentAmount && isValidAmount;

  return (
    <>
      <BackButton to={backButtonLink}>
        <Typography variant="h3">
          <FormattedMessage id="common.button.back" />
        </Typography>
      </BackButton>

      <PageTitle>
        <Typography variant="h1">
          <FormattedMessage id={pageTitleId} />
        </Typography>
      </PageTitle>

      <FormSectionContainer>
        <Text style={{ marginBottom: '.5em' }}>
          <Typography variant="p">
            <FormattedMessage id={inputLabelId} />
          </Typography>
        </Text>

        <Input
          type="number"
          name="treatment_amount"
          placeholder="70,00"
          value={treatmentAmount}
          suffix="€"
          onBlur={handleTreatmentAmountBlur}
          onChange={handleTreatmentAmountChange}
          onKeyDown={handleTreatmentAmountKeyDown}
          data-testid="new-claim-treatment-amount-input"
        />

        {!isValidAmount && (
          <ErrorMessage data-testid="error-invalid-amount">
            <Typography variant="p">
              <FormattedMessage id={errorMessageId} />
            </Typography>
          </ErrorMessage>
        )}
      </FormSectionContainer>

      <Text style={{ marginTop: '1rem' }}>
        <Typography variant="p">
          <FormattedMessage id="new_claim.treatment_amount.helper_text" />
        </Typography>
      </Text>

      {hintTextId && (
        <StyledHintContainer>
          <Text>
            <Typography variant="p">
              <FormattedMessage id={hintTextId} />
            </Typography>
          </Text>
        </StyledHintContainer>
      )}

      <ButtonContainer>
        <Button
          onClick={() => history.push(nextButtonLink)}
          disabled={!isFormValid}
          data-testid="next_button"
        >
          <Typography variant="buttons">
            <FormattedMessage id="common.button.next" />
          </Typography>
        </Button>
      </ButtonContainer>
    </>
  );
}

TreatmentAmount.defaultProps = {
  maxLength: 7,
};

TreatmentAmount.propTypes = {
  maxLength: PropTypes.number,
  backButtonLink: PropTypes.string,
  nextButtonLink: PropTypes.string,
  pageTitleId: PropTypes.string,
  treatmentAmount: oneOfType([PropTypes.number, PropTypes.string]),
  setTreatmentAmount: PropTypes.func,
  errorMessageId: PropTypes.string,
  hintTextId: PropTypes.string,
};
