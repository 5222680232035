import React from 'react';
import { Link } from 'react-router-dom';
import { css, styled } from '@mui/material';
import PropTypes from 'prop-types';

import { MIN_WIDTH_MD } from '../../theme/breakpoints';

import { Button, Paragraph } from '.';
import { Icon, Paper } from '../common';

const StyledInfoCard = styled(Paper)`
  padding: 2rem;

  > p {
    margin-top: 1.5rem;
  }
`;

const ParagraphWithIcon = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  img {
    margin: 0 auto;
  }

  > p {
    margin-top: 1rem;
  }

  @media (min-width: ${MIN_WIDTH_MD}) {
    flex-direction: row;
    align-items: center;

    img {
      margin: 0;
    }

    > p {
      margin-left: 2rem;
      margin-top: 0;
    }
  }
`;

const LinkStyles = ({ theme }) => css`
  display: block;
  margin-top: 1.5rem;
  width: fit-content;
  font-size: 1rem;
  font-weight: 450;
  color: ${theme.palette.main.professional_blue};

  @media (min-width: ${MIN_WIDTH_MD}) {
    font-size: 1.25rem;
  }
`;

const StyledLink = styled(Link)`
  ${LinkStyles}
`;

const StyledHref = styled('a')`
  ${LinkStyles}
`;

const icons = {
  success: 'info.success',
  failure: 'info.warning',
};

export const StyledSuccessTipContainer = styled('div')(
  ({ theme }) => css`
    padding: 1rem;
    background-color: red;
    margin-top: 1.5rem;
    background-color: ${theme.palette.background.info};

    font-size: 1.125rem;
    line-height: 24px;

    @media (min-width: ${MIN_WIDTH_MD}) {
      font-size: 1.25rem;
      line-height: 26px;
    }
  `
);

export const StyledBoldSuccessTip = styled('span')`
  display: block;
  font-weight: 700;
`;

const StyledSuccessTipListContainer = styled('div')`
  margin-top: 1rem;
`;

const StyledSuccessTipList = styled('ul')`
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 1.25rem;
`;

export default function InfoCard(props) {
  const {
    mode,
    title,
    description,
    description2,
    button,
    tipQuestion,
    tipDescription,
    tipListTitle,
    tipItem1,
    tipItem2,
    ...rest
  } = props;

  return (
    <StyledInfoCard {...rest}>
      <ParagraphWithIcon>
        <Icon icon={icons[`${mode}`]} alt={mode} />

        <Paragraph>{title}</Paragraph>
      </ParagraphWithIcon>

      {description && <Paragraph>{description}</Paragraph>}
      {description2 && <Paragraph>{description2}</Paragraph>}
      {button && <InfoCardLink {...button} />}

      {tipQuestion && (
        <StyledSuccessTipContainer>
          <StyledBoldSuccessTip>{tipQuestion}</StyledBoldSuccessTip>
          {tipDescription}

          <StyledSuccessTipListContainer>
            {tipListTitle}

            <StyledSuccessTipList>
              <li>{tipItem1}</li>
              <li>{tipItem2}</li>
            </StyledSuccessTipList>
          </StyledSuccessTipListContainer>
        </StyledSuccessTipContainer>
      )}
    </StyledInfoCard>
  );
}

InfoCard.defaultProps = {
  mode: 'success',
};

InfoCard.propTypes = {
  mode: PropTypes.string,
  titleHtml: PropTypes.string,
  textHtml: PropTypes.string,
};

function InfoCardLink(props) {
  const { text, func, href, to, target, variant } = props;

  if (func) {
    return (
      <Button variant={variant} onClick={func}>
        {text}
      </Button>
    );
  }

  if (to) {
    return <StyledLink to={to}>{text}</StyledLink>;
  }

  if (href) {
    return (
      <StyledHref href={href} target={target}>
        {text}
      </StyledHref>
    );
  }

  return null;
}

InfoCardLink.propTypes = {
  text: PropTypes.string.isRequired,
  func: PropTypes.func,
  href: PropTypes.string,
  to: PropTypes.string,
  target: PropTypes.string,
};
