import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import { showMoney } from '../../shared';

import { Separator } from '../common';
import { StyledFlexContainer } from '../styled/PolicyInfo.styled';
import {
  StyledPolicyDetailsSectionTitle,
  StyledPolicyDetailsTextLg,
  StyledPolicyDetailsText,
} from '../styled/PolicyDetails.styled';

const getLabel = (customLabels, intl) => (age, isLast) => {
  const validatedcustomLabels = Array.isArray(customLabels) ? customLabels : [];

  const validatedAge = Array.isArray(age) && age.length === 2 ? age : [0, 0];
  const [startAge, endAge] = validatedAge;
  const validatedStartAge = Number.isNaN(parseInt(startAge, 10)) ? 0 : startAge;
  const validatedEndAge = Number.isNaN(parseInt(endAge, 10)) ? 0 : endAge;

  const customLabelKey = `${validatedStartAge}-${validatedEndAge}`;
  const customLabelInfo = validatedcustomLabels.find((info) => info?.age === customLabelKey);

  const yearsText = intl.formatMessage({ id: 'policy.all_details.premium.years' });

  if (customLabelInfo) {
    return customLabelInfo?.label;
  }

  if (isLast) {
    return `${validatedStartAge - 1}+ ${yearsText}`;
  }

  return `${validatedStartAge} - ${validatedEndAge} ${yearsText}`;
};

const Premium = ({ premiumInfo, contractPremium }) => {
  const intl = useIntl();

  const { isHidden, customLabels } = premiumInfo || {};
  const getAgeLabel = getLabel(customLabels, intl);

  if (isHidden || !Array.isArray(contractPremium)) return null;

  return (
    <div style={{ marginBottom: '1rem' }}>
      <Separator />

      <StyledPolicyDetailsSectionTitle>
        <Typography variant="h3">
          <FormattedMessage id="policy.all_details.premium.title" />:
        </Typography>
      </StyledPolicyDetailsSectionTitle>

      {contractPremium.map(({ age, price }, index) => {
        const isLast = index === contractPremium.length - 1;

        return (
          <div key={price}>
            {index !== 0 && <Separator />}

            <StyledFlexContainer marginBottom="1rem">
              <StyledPolicyDetailsText>
                <Typography variant="p">{getAgeLabel(age, isLast)}</Typography>
              </StyledPolicyDetailsText>
              <StyledPolicyDetailsTextLg>
                <Typography variant="h3">{showMoney(price)}</Typography>
              </StyledPolicyDetailsTextLg>
            </StyledFlexContainer>
          </div>
        );
      })}
    </div>
  );
};

export default Premium;
