import React from 'react';
import { Typography } from '@mui/material';

import { Separator } from '../common';
import { StyledFlexContainer } from '../styled/PolicyInfo.styled';
import {
  StyledPolicyDetailsSectionTitle,
  StyledPolicyDetailsTextLg,
  StyledPolicyDetailsText,
} from '../styled/PolicyDetails.styled';

const MainDetails = ({ details }) => {
  if (!Array.isArray(details)) return null;

  return details.map((item) => (
    <div key={item?.title || item?.subText}>
      <StyledPolicyDetailsSectionTitle>
        <Typography variant="h3">{item?.title}</Typography>
      </StyledPolicyDetailsSectionTitle>

      {Array.isArray(item?.rates) &&
        item.rates.map((rate) => (
          <StyledFlexContainer marginBottom="1rem" key={rate?.name}>
            <StyledPolicyDetailsText>
              <Typography variant="p">{rate?.name}</Typography>
            </StyledPolicyDetailsText>

            <StyledPolicyDetailsTextLg textAlign="right">
              <Typography variant="h3">{rate?.value}</Typography>
            </StyledPolicyDetailsTextLg>
          </StyledFlexContainer>
        ))}

      {!!item?.subText && (
        <StyledPolicyDetailsText>
          <Typography variant="p">{item.subText}</Typography>
        </StyledPolicyDetailsText>
      )}

      <Separator />
    </div>
  ));
};

export default MainDetails;
