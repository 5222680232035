import React, { useState, useEffect, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Typography } from '@mui/material';

import useDebounce from '../../../../hooks/useDebounce';

import { Button } from '../../../../components/ui';
import {
  BackButton,
  ButtonContainer,
  ErrorMessage,
  PageTitle,
  Text,
  TextArea,
} from '../../../../components/common';

const CustomerNote = ({
  backButtonLink,
  nextButtonLink,
  pageTitleId,
  maxWords,
  pageDescriptionId,
  customerNote,
  setCustomerNote,
  placeholder,
  errorMessageId,
  showNextButton = true,
  skipText = '',
  customerNoteRequired = true,
}) => {
  const history = useHistory();
  const intl = useIntl();
  const [isValid, setIsValid] = useState(true);
  const [text, setText] = useState(customerNote);
  const debouncedInfoText = useDebounce(text, 500);

  const maxLetterExceeded = useCallback(
    (t) => {
      return t.trim().split(/\s+/).length > maxWords;
    },
    [maxWords]
  );
  useEffect(() => {
    if (debouncedInfoText) {
      if (maxLetterExceeded(debouncedInfoText)) {
        setCustomerNote('');
        setIsValid(false);
      } else {
        setCustomerNote(debouncedInfoText);
        setIsValid(true);
      }
    } else {
      setCustomerNote('');
    }
  }, [debouncedInfoText, setCustomerNote, maxLetterExceeded]);

  const handleCustomerNoteChange = (ev) => {
    if (!ev) return;
    const value = ev?.target?.value;
    setText(value);
  };

  const defaultErrorMessageId = 'new_claim.date.info_text_max_error_message';

  return (
    <>
      <BackButton to={backButtonLink}>
        <Typography variant="h3">
          <FormattedMessage id="common.button.back" />
        </Typography>
      </BackButton>

      <PageTitle>
        <Typography variant="h1">
          <FormattedMessage id={pageTitleId} />
        </Typography>
      </PageTitle>

      <Text style={{ marginBottom: '1.5rem' }}>
        <Typography variant="p">
          <FormattedMessage id={pageDescriptionId} />
        </Typography>
      </Text>

      <TextArea
        name="treatment_Info_box"
        placeholder={intl.formatMessage({
          id: placeholder,
        })}
        value={text}
        onChange={handleCustomerNoteChange}
        data-testid="treatment-info-text"
      />

      {!isValid && (
        <ErrorMessage>
          <Typography variant="p">
            <FormattedMessage id={errorMessageId || defaultErrorMessageId} />
          </Typography>
        </ErrorMessage>
      )}

      {skipText && (
        <Text style={{ marginBottom: '1.5rem', marginTop: '1rem' }}>
          <Typography variant="p">
            <FormattedMessage id={skipText} />
          </Typography>
        </Text>
      )}

      {showNextButton && (
        <ButtonContainer>
          <Button
            variant="primary"
            disabled={
              customerNoteRequired && (!debouncedInfoText || maxLetterExceeded(debouncedInfoText))
            }
            onClick={() => history.push(nextButtonLink)}
          >
            <Typography variant="buttons">
              <FormattedMessage id="common.button.next" />
            </Typography>
          </Button>
        </ButtonContainer>
      )}
    </>
  );
};

export default CustomerNote;
