import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import { fuzzyDentistOptions, dentistSearchTermCleaner } from './helpers';

import { FuzzySelect, Text } from '../../../../components/common';

const DentistInput = ({
  selectOptions,
  selectedOption,
  setDentistId,
  setDentistName,
  setIsDentoloDentist,
}) => {
  const handleSelect = (newSelectedOption) => {
    const { label, value, isDentoloDentist, __isNew__ } = newSelectedOption || {};

    setDentistName(label);

    if (__isNew__) {
      setDentistId(null);
      setIsDentoloDentist(false);
    } else {
      setDentistId(value);
      setIsDentoloDentist(isDentoloDentist);
    }
  };

  const handleBlur = (ev) => {
    const value = ev?.target?.value;

    if (!selectedOption && !!value) {
      setDentistName(value);
      setDentistId(null);
      setIsDentoloDentist(false);
    }
  };

  return (
    <>
      <Text style={{ marginBottom: '.5em' }}>
        <Typography variant="p">
          <FormattedMessage id="dentolo.new_claim.dentist_details.input_label" />
        </Typography>
      </Text>

      <FuzzySelect
        options={selectOptions}
        fuzzyOptions={fuzzyDentistOptions}
        searchTextCleaner={dentistSearchTermCleaner}
        placeholder="Dr. Max Mustermann"
        data-testid="select_dentist"
        allowCreateWhileLoading={false}
        formatCreateLabel={(inputValue) => inputValue}
        isClearable
        isLoading={!selectOptions.length}
        value={selectedOption}
        onChange={handleSelect}
        onBlur={handleBlur}
        blurInputOnSelect={false}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
      />
    </>
  );
};

export default DentistInput;
