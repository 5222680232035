import React from 'react';
import { useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { CSSTransition } from 'react-transition-group';
import { Typography } from '@mui/material';

import { GET_REFERRAL_LIST, imgLinks, useReady } from '../../../../shared';

import ReferralList from '../ReferralList';
import ReferralStatusDescription from '../ReferralStatusDescription';
import { Button, Loading } from '../../../../components/ui';
import { PageTitle } from '../../../../components/common';
import {
  StyledReferralCard,
  StyledReferralMoreInformationContainer,
  StyledReferralMoreInformationText,
  StyledReferralMoreInformationTitle,
} from '../ReferralPage.styled';
import {
  StyledPetoloReferralStatusEmptyContainer,
  StyledPetoloReferralStatusEmptyDescription,
  StyledPetoloReferralStatusEmptyImageContainer,
  StyledPetoloReferralStatusEmptyImg,
  StyledPetoloReferralStatusEmptyTitle,
} from './PetoloReferral.styled';

const referralStatusDescription = [
  {
    id: 1,
    formattedDescription: (
      <FormattedMessage
        id="referral.status.description.pending"
        values={{ b: (chunks) => <strong>{chunks}</strong> }}
      />
    ),
  },
  {
    id: 2,
    formattedDescription: (
      <FormattedMessage
        id="referral.status.description.cancelled"
        values={{ product: 'petolo', b: (chunks) => <strong>{chunks}</strong> }}
      />
    ),
  },
  {
    id: 3,
    formattedDescription: (
      <FormattedMessage
        id="referral.status.description.completed"
        values={{
          amount: 50,
          giftbrand: 'Fressnapf-Gutschein',
          b: (chunks) => <strong>{chunks}</strong>,
        }}
      />
    ),
  },
];

const transitionTimeout = 500;

function PetoloReferralStatus({ onReferralInfoLinkClick }) {
  const ready = useReady();

  const { data, loading, refetch } = useQuery(GET_REFERRAL_LIST);
  const { referrals } = data || {};

  React.useEffect(() => {
    refetch();
  }, [refetch]);

  if (loading) return <Loading showLogo />;

  const showEmptyState = !loading && (!Array.isArray(referrals) || referrals.length <= 0);

  return (
    <CSSTransition in={ready} timeout={transitionTimeout} classNames="fade" unmountOnExit>
      <>
        {showEmptyState ? (
          <EmptyPetoloReferralStatus />
        ) : (
          <StyledReferralCard>
            <PageTitle>
              <Typography variant="h1">
                <FormattedMessage id="referral.page.status.title" />
              </Typography>
            </PageTitle>

            <ReferralList referrals={referrals} />

            <ReferralStatusDescription
              titleId="referral.status.description.title"
              statusDescription={referralStatusDescription}
            />
          </StyledReferralCard>
        )}

        <PetoloReferralMoreInformation onReferralInfoLinkClick={onReferralInfoLinkClick} />
      </>
    </CSSTransition>
  );
}

export default PetoloReferralStatus;

const EmptyPetoloReferralStatus = () => {
  return (
    <StyledPetoloReferralStatusEmptyContainer>
      <StyledPetoloReferralStatusEmptyTitle>
        <Typography variant="h2">
          <FormattedMessage id="referral.page.status.empty.title" />
        </Typography>
      </StyledPetoloReferralStatusEmptyTitle>

      <StyledPetoloReferralStatusEmptyImageContainer>
        <StyledPetoloReferralStatusEmptyImg
          src={imgLinks['image.petolo.referral.fressnapf.voucher']}
          alt="petolo referral"
        />
      </StyledPetoloReferralStatusEmptyImageContainer>

      <StyledPetoloReferralStatusEmptyDescription>
        <Typography variant="p">
          <FormattedMessage
            id="referral.page.status.empty.description"
            values={{ product: 'petolo' }}
          />
        </Typography>
      </StyledPetoloReferralStatusEmptyDescription>
    </StyledPetoloReferralStatusEmptyContainer>
  );
};

const PetoloReferralMoreInformation = ({ onReferralInfoLinkClick }) => {
  const renderAnchorTagForMoreInfo = (chunk, fn) => (
    <Button variant="nostyle" onClick={fn}>
      <Typography variant="p">{chunk}</Typography>
    </Button>
  );

  return (
    <StyledReferralMoreInformationContainer>
      <StyledReferralMoreInformationTitle>
        <Typography variant="h3">Haben Sie Fragen?</Typography>
      </StyledReferralMoreInformationTitle>

      <StyledReferralMoreInformationText>
        <Typography variant="p">
          <FormattedMessage
            id="referral.page.petolo.more_info.text"
            values={{
              a: (chunk) => renderAnchorTagForMoreInfo(chunk, onReferralInfoLinkClick),
            }}
          />
        </Typography>
      </StyledReferralMoreInformationText>
    </StyledReferralMoreInformationContainer>
  );
};
