import React, { useState } from 'react';
import { Tab, TabPanel, Tabs } from 'react-tabs';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import DentoloReferralInformation from './DentoloReferralInformation';
import DentoloReferralStatus from './DentoloReferralStatus';
import { Container, PageTitle } from '../../../../components/common';
import { StyledReferralTabList } from '../ReferralPage.styled';

function DentoloReferral() {
  const [selectedTabIdx, setSelectedTabIdx] = useState(0);

  const handleSelectTab = (index) => {
    setSelectedTabIdx(index);
  };

  return (
    <Container>
      <PageTitle data-test-id="pagename_referral_dentolo">
        <Typography variant="h1">
          <FormattedMessage
            id="referral.page.dentolo.title"
            values={{ amount: 50, couponbrand: 'Amazon Gutschein' }}
          />
        </Typography>
      </PageTitle>

      <Tabs
        selectedIndex={selectedTabIdx}
        selectedTabClassName="selected-referral-tab"
        onSelect={handleSelectTab}
      >
        <StyledReferralTabList>
          <Tab>
            <Typography variant="h3">
              <FormattedMessage id="referral.page.tab.label.one" />
            </Typography>
          </Tab>
          <Tab>
            <Typography variant="h3">
              <FormattedMessage id="referral.page.tab.label.two" />
            </Typography>
          </Tab>
        </StyledReferralTabList>

        <TabPanel>
          <DentoloReferralInformation />
        </TabPanel>

        <TabPanel>
          <DentoloReferralStatus />
        </TabPanel>
      </Tabs>
    </Container>
  );
}

export default DentoloReferral;
