import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

import usePetoloClaims from '../../usePetoloClaims';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { MIN_WIDTH_MD } from '../../../../theme/breakpoints';

import { BackButton, Paper, Paragraph, Title } from '../../../../components/common';
import { RightListItemIconPetolo, WrongListItemIcon } from '../../../../components/common/Icon';
import { Button } from '../../../../components/ui';
import FileUploader from './FileUploader';
import FileList from './FileList';
import PetoloDocumentIllustration from './PetoloDocumentIllustration';
import {
  StyledButtonContainer,
  StyledFileList,
  StyledUploadDocumentHintDesktopIllustrationContainer,
  StyledUploadDocumentHintMainInformationContainer,
  StyledUploadDocumentHintMobileIllustrationContainer,
  StyledUploadDocumentHintsList,
  StyledUploadDocumentHintsListInnerContainer,
  StyledUploadDocumentHintsListOuterContainer,
  StyledUploadDocumentsHintBadStuffTitle,
} from './FileUploader.styled';
import {
  usePrefillSomeFormFieldsForExistingPetoloClaim,
  useUploadTreatmentDocumentPetoloBackButtonLink,
  useUploadTreatmentDocumentPetoloNextButtonLink,
} from './uploadTreatmentDocumentPetoloHooks';
import {
  PETOLO_DOCUMENT_TYPE,
  PETOLO_DOCUMENT_TYPE_INVOICE,
  PETOLO_IS_EXISTING_CLAIM_ANSWER,
  PET_HEALTH_TREATMENT_CATEGORIES,
  useNewPetoloFormDataValue,
} from '../../claimFormData/newClaimFormData';

export default function UploadTreatmentDocumentPetolo(props) {
  const { selectedDocuments, setSelectedDocuments, setClaimSubmitted, claimsCount } = props;

  const isDesktop = useMediaQuery(`(min-width: ${MIN_WIDTH_MD})`);

  const validSelectedDocuments = Array.isArray(selectedDocuments)
    ? selectedDocuments.filter((d) => d?.valid)
    : [];

  const history = useHistory();
  const { claimId } = useParams();

  const [submitPetoloClaim, { loading: submitting }] = usePetoloClaims(claimId);

  const nextButtonLink = useUploadTreatmentDocumentPetoloNextButtonLink(claimId);

  const addFileHandler = (currFiles) => {
    setSelectedDocuments((state) => [...state, ...currFiles]);
  };

  const removeFileHandler = (ev, id) => {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }

    const updatedFileList = selectedDocuments?.filter((item) => item?.fileId !== id);
    setSelectedDocuments(updatedFileList);
  };

  const nextStepHandler = () => {
    history.push(nextButtonLink);
  };

  const handleSubmitClaim = () => {
    const payload = {
      files: validSelectedDocuments.map((doc) => doc.file),
    };

    submitPetoloClaim({
      payload,
      setSelectedDocuments,
      setClaimSubmitted,
      clearFormData: false,
    });
  };

  const hasFiles = selectedDocuments?.length > 0;

  const [, /* treatmentCategories */ setTreatmentCategories] = useNewPetoloFormDataValue(
    PET_HEALTH_TREATMENT_CATEGORIES
  );
  const [documentType, setDocumentType] = useNewPetoloFormDataValue(PETOLO_DOCUMENT_TYPE);
  const [existingClaimAnswer] = useNewPetoloFormDataValue(PETOLO_IS_EXISTING_CLAIM_ANSWER);

  usePrefillSomeFormFieldsForExistingPetoloClaim(claimId, setTreatmentCategories, setDocumentType);

  const backButtonLink = useUploadTreatmentDocumentPetoloBackButtonLink(
    claimId,
    documentType,
    claimsCount,
    existingClaimAnswer
  );

  const shouldSubmitForm = !nextButtonLink;

  return (
    <>
      <BackButton
        label={
          <Typography variant="h3">
            <FormattedMessage id="common.button.back" />
          </Typography>
        }
        to={backButtonLink}
      />

      <Title>
        <Typography variant="h3">
          <FormattedMessage
            id={
              hasFiles
                ? 'new_claim.upload.page.title.with.files'
                : 'new_claim.upload.page.title.without.files'
            }
          />
        </Typography>
      </Title>

      {!hasFiles && (
        <Paragraph>
          <Typography variant="p">
            <FormattedMessage id="new_claim.upload.page.description" />
          </Typography>
        </Paragraph>
      )}

      {hasFiles && (
        <StyledFileList>
          <FileList files={selectedDocuments} onRemove={removeFileHandler} />
        </StyledFileList>
      )}

      <Paper>
        <FileUploader selectedFiles={selectedDocuments} setSelectedFiles={addFileHandler} />

        {isDesktop && (
          <StyledButtonContainer hasFiles={hasFiles}>
            {shouldSubmitForm ? (
              <Button
                onClick={handleSubmitClaim}
                disabled={submitting || !validSelectedDocuments.length}
              >
                <Typography variant="buttons">
                  <FormattedMessage id="petolo.new_claim.submit_claim_form.send_button" />
                </Typography>
              </Button>
            ) : (
              <Button onClick={nextStepHandler} disabled={!validSelectedDocuments.length}>
                <Typography variant="buttons">
                  <FormattedMessage id="common.button.next" />
                </Typography>
              </Button>
            )}
          </StyledButtonContainer>
        )}
      </Paper>

      {!isDesktop && (
        <StyledButtonContainer hasFiles={hasFiles}>
          {shouldSubmitForm ? (
            <Button
              onClick={handleSubmitClaim}
              disabled={submitting || !validSelectedDocuments.length}
            >
              <Typography variant="buttons">
                <FormattedMessage id="petolo.new_claim.submit_claim_form.send_button" />
              </Typography>
            </Button>
          ) : (
            <Button onClick={nextStepHandler} disabled={!validSelectedDocuments.length}>
              <Typography variant="buttons">
                <FormattedMessage id="common.button.next" />
              </Typography>
            </Button>
          )}
        </StyledButtonContainer>
      )}

      {documentType === PETOLO_DOCUMENT_TYPE_INVOICE && !hasFiles && (
        <Paper style={{ display: 'flex', marginTop: '1rem' }}>
          <StyledUploadDocumentHintMainInformationContainer>
            <Paragraph>
              <Typography variant="p">
                <FormattedMessage id="new_claim.upload.page.hint.description" />
              </Typography>
            </Paragraph>

            <StyledUploadDocumentHintsListOuterContainer>
              <StyledUploadDocumentHintsListInnerContainer>
                <StyledUploadDocumentHintsList>
                  <RightListItemIconPetolo />
                  <Typography variant="p">
                    <FormattedMessage id="new_claim.upload.page.hint.good_stuff_pet_1" />
                  </Typography>
                </StyledUploadDocumentHintsList>

                <StyledUploadDocumentHintsList>
                  <RightListItemIconPetolo />
                  <FormattedMessage id="new_claim.upload.page.hint.good_stuff_2" />
                </StyledUploadDocumentHintsList>

                <StyledUploadDocumentHintsList>
                  <RightListItemIconPetolo />
                  <Typography variant="p">
                    <FormattedMessage id="new_claim.upload.page.hint.good_stuff_3" />
                  </Typography>
                </StyledUploadDocumentHintsList>

                <StyledUploadDocumentHintsList>
                  <RightListItemIconPetolo />
                  <Typography variant="p">
                    <FormattedMessage id="new_claim.upload.page.hint.good_stuff_4" />
                  </Typography>
                </StyledUploadDocumentHintsList>

                <StyledUploadDocumentsHintBadStuffTitle>
                  <Typography variant="p">
                    <FormattedMessage id="new_claim.upload.page.hint.bad_stuff_title" />
                  </Typography>
                </StyledUploadDocumentsHintBadStuffTitle>

                <StyledUploadDocumentHintsList>
                  <WrongListItemIcon />
                  <Typography variant="p">
                    <FormattedMessage id="new_claim.upload.page.hint.bad_stuff_1" />
                  </Typography>
                </StyledUploadDocumentHintsList>

                <StyledUploadDocumentHintsList>
                  <WrongListItemIcon />
                  <Typography variant="p">
                    <FormattedMessage id="new_claim.upload.page.hint.bad_stuff_2" />
                  </Typography>
                </StyledUploadDocumentHintsList>

                <StyledUploadDocumentHintsList>
                  <WrongListItemIcon />
                  <Typography variant="p">
                    <FormattedMessage id="new_claim.upload.page.hint.bad_stuff_3" />
                  </Typography>
                </StyledUploadDocumentHintsList>
              </StyledUploadDocumentHintsListInnerContainer>

              <StyledUploadDocumentHintMobileIllustrationContainer>
                <PetoloDocumentIllustration />
              </StyledUploadDocumentHintMobileIllustrationContainer>
            </StyledUploadDocumentHintsListOuterContainer>
          </StyledUploadDocumentHintMainInformationContainer>

          <StyledUploadDocumentHintDesktopIllustrationContainer>
            <PetoloDocumentIllustration />
          </StyledUploadDocumentHintDesktopIllustrationContainer>
        </Paper>
      )}
    </>
  );
}

UploadTreatmentDocumentPetolo.propTypes = {
  selectedDocuments: PropTypes.arrayOf(
    PropTypes.shape({
      fileId: PropTypes.string,
      valid: PropTypes.bool,
    })
  ),
  setSelectedDocuments: PropTypes.func,
};
