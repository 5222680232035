import { css, styled } from '@mui/material';

const StyledPageTitle = styled('h1')(
  ({ theme }) => css`
    margin-bottom: 1.5rem;
    color: ${theme.palette.main.professional_blue};
    word-break: break-word;
  `
);

export default StyledPageTitle;
