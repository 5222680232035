import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import useInsuranceInfo from '../../hooks/useInsuranceInfo';
import {
  getDateProtectionLink,
  getFooterCopyright,
  getImprintLink,
  getSupportLink,
} from './footerUtils';

import BetaBranchName from './BetaBranchName';
import {
  StyledFooterContainer,
  StyledFooterCopyright,
  StyledFooterLinkSeparator,
  StyledFooterLinksWrapper,
  StyledFooterWrapper,
} from './Footer.styled';

const Footer = () => {
  const { type: insuranceType } = useInsuranceInfo();
  const footerCopyrights = getFooterCopyright(insuranceType);
  const imprintLink = getImprintLink(insuranceType);
  const dateProtectionLink = getDateProtectionLink(insuranceType);
  const supportLink = getSupportLink(insuranceType);

  const handleCookieSettingsClick = (ev) => {
    if (ev) ev.preventDefault();

    if (!window.UC_UI) return;

    window.UC_UI.showSecondLayer();
  };

  return (
    <StyledFooterContainer>
      <StyledFooterWrapper>
        <StyledFooterLinksWrapper>
          <a href={imprintLink} target="_blank" rel="noopener noreferrer">
            <Typography variant="p">
              <FormattedMessage id="common.title.imprint" />
            </Typography>
          </a>

          <StyledFooterLinkSeparator>|</StyledFooterLinkSeparator>

          <a href={dateProtectionLink} target="_blank" rel="noopener noreferrer">
            <Typography variant="p">
              <FormattedMessage id="common.title.data.protection" />
            </Typography>
          </a>

          <StyledFooterLinkSeparator>|</StyledFooterLinkSeparator>

          <button type="button" onClick={handleCookieSettingsClick}>
            <Typography variant="p">
              <FormattedMessage id="common.title.cookie.settings" />
            </Typography>
          </button>

          <StyledFooterLinkSeparator>|</StyledFooterLinkSeparator>

          <a href={supportLink} target="_blank" rel="noopener noreferrer">
            <Typography variant="p">
              <FormattedMessage id="common.title.help" />
            </Typography>
          </a>
        </StyledFooterLinksWrapper>

        <StyledFooterCopyright>
          <Typography variant="p">{footerCopyrights}</Typography>
        </StyledFooterCopyright>
      </StyledFooterWrapper>

      <BetaBranchName />
    </StyledFooterContainer>
  );
};

export default Footer;
