import { css, styled } from '@mui/material';

import { MIN_WIDTH_MD } from '../../theme/breakpoints';

const StyledTitle = styled('h1')(
  ({ theme }) => css`
    margin-bottom: 0.5rem;
    color: ${theme.palette.main.professional_blue};

    @media (min-width: ${MIN_WIDTH_MD}) {
      margin-bottom: 1rem;
    }
  `
);
export default StyledTitle;
