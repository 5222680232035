import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import { imgLinks } from '../../shared';
import { MIN_WIDTH_MD } from '../../theme/breakpoints';
import useMediaQuery from '../../hooks/useMediaQuery';

import {
  StyledErrorPlaceholder,
  StyledErrorPlaceholderText,
  StyledErrorPlaceholderTitle,
} from './styled/ErrorPlaceholder.styled';

function ErrorPlaceholder() {
  const supportEmail = 'service@dentolo.de';

  const isDesktop = useMediaQuery(`(min-width: ${MIN_WIDTH_MD})`);

  return (
    <StyledErrorPlaceholder>
      <StyledErrorPlaceholderTitle>
        <Typography variant="h3">
          <FormattedMessage
            id={
              isDesktop ? 'error.message.common.title.desktop' : 'error.message.common.title.mobile'
            }
          />
        </Typography>
      </StyledErrorPlaceholderTitle>

      <StyledErrorPlaceholderText type="subtext" style={{ marginBottom: '0.5rem' }}>
        <Typography variant="h3">
          <FormattedMessage
            id={
              isDesktop
                ? 'error.message.common.subtitle.desktop'
                : 'error.message.common.subtitle.mobile'
            }
          />
        </Typography>
      </StyledErrorPlaceholderText>

      <img src={imgLinks['icon.error.message.placeholder']} alt="An error occurred" />

      {!isDesktop && (
        <StyledErrorPlaceholderText type="subtext">
          <Typography variant="p">
            <FormattedMessage id="error.message.common.subtitle.desktop" />
          </Typography>
        </StyledErrorPlaceholderText>
      )}

      <StyledErrorPlaceholderText type="subtext">
        <Typography variant="p">
          <FormattedMessage id="error.message.common.support" />:{' '}
          <label htmlFor="support-email">{supportEmail}</label>
        </Typography>
      </StyledErrorPlaceholderText>

      <StyledErrorPlaceholderText type="subtext">
        <Typography variant="p">
          <FormattedMessage id="error.message.common.reward" />
        </Typography>
      </StyledErrorPlaceholderText>

      <StyledErrorPlaceholderText type="subtext" style={{ marginTop: 0 }}>
        <Typography variant="p">
          <FormattedMessage id="error.message.common.team" />
        </Typography>
      </StyledErrorPlaceholderText>
    </StyledErrorPlaceholder>
  );
}

export default ErrorPlaceholder;
