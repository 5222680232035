import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import { formatPolicyName } from '../../shared';

const PolicyAndAddonName = ({ customer, addons, shouldFormatPolicyName }) => {
  const addOnTitle = addons?.title || '';
  const { policyName } = customer?.contract || {};

  if (shouldFormatPolicyName) {
    if (addOnTitle) {
      return (
        <Typography variant="pـcolorless">
          <FormattedMessage id={`policies.${formatPolicyName(policyName)}`} />
          <span>{` + ${addOnTitle}`}</span>
        </Typography>
      );
    }

    return (
      <Typography variant="pـcolorless">
        <FormattedMessage id={`policies.${formatPolicyName(policyName)}`} />
      </Typography>
    );
  }

  return <span>{policyName}</span>;
};

export default PolicyAndAddonName;
