import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useIntl } from 'react-intl';
import PropTypes, { shape } from 'prop-types';
import { Typography } from '@mui/material';

import useMediaQuery from '../../../../hooks/useMediaQuery';
import { useFileUploadIcon } from '../../../../hooks/useIcons';
import { MIN_WIDTH_MD } from '../../../../theme/breakpoints';

import { IconButton } from '../../../../components/common';
import { Paragraph } from '../../../../components/ui';
import { StyledUploadButtonContainer, StyledUploadButtonLabel } from './FileUploader.styled';

function FileUploader(props) {
  const { selectedFiles, setSelectedFiles, descriptionIdWithFiles, descriptionIdWithoutFiles } =
    props;

  const intl = useIntl();
  const isDesktop = useMediaQuery(`(min-width: ${MIN_WIDTH_MD})`);

  const acceptTypes = ['image/png', 'image/jpeg', 'image/tiff', 'application/pdf'];
  const maxFileSize = 20000000;
  const maxFilesToUpload = 50;

  const dropAcceptedHandler = (files) => {
    let currentFiles = [];

    files.forEach((file) => {
      const samePath = selectedFiles?.filter((item) => item?.file?.path === file?.path);

      if (samePath?.length > 0) return;

      const uid = Math.random().toString(16).slice(2);

      const newFile = {
        file,
        fileId: `new-file-${uid}`,
        valid: true,
        error: '',
      };

      if (!acceptTypes.includes(file?.type)) {
        newFile.valid = false;
        newFile.error = intl.formatMessage({ id: 'new_claim.file.wrong.type' });
      }

      if (file?.size > maxFileSize) {
        newFile.valid = false;
        newFile.error = intl.formatMessage({ id: 'new_claim.file.too.big' });
      }

      currentFiles = [...currentFiles, newFile];
    });

    setSelectedFiles(currentFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptTypes,
    disabled: selectedFiles?.length >= maxFilesToUpload,
    onDropAccepted: (acceptedFiles) => dropAcceptedHandler(acceptedFiles),
  });

  const hasFiles = selectedFiles?.length > 0;
  const selectButtonIcon = useFileUploadIcon();

  const buttonTitle = intl.formatMessage({
    id: hasFiles
      ? 'new_claim.file.uploader.title.with_files'
      : 'new_claim.file.uploader.title.without_files',
  });
  const description = intl.formatMessage({
    id: hasFiles ? descriptionIdWithFiles : descriptionIdWithoutFiles,
  });

  return (
    <>
      <StyledUploadButtonContainer {...getRootProps()}>
        <input {...getInputProps()} />
        <IconButton
          icon={selectButtonIcon}
          size="md"
          alt="Select files"
          data-testid="select-files-button"
        />

        {!hasFiles && isDesktop ? (
          <Paragraph>
            <Typography variant="p">{description}</Typography>
          </Paragraph>
        ) : (
          <StyledUploadButtonLabel>
            <Typography variant="p">{buttonTitle}</Typography>
          </StyledUploadButtonLabel>
        )}
      </StyledUploadButtonContainer>

      {isDesktop && hasFiles && (
        <Paragraph style={{ marginTop: '1rem' }}>
          <Typography variant="p">{description}</Typography>
        </Paragraph>
      )}
    </>
  );
}

export default FileUploader;

FileUploader.defaultProps = {
  descriptionIdWithoutFiles: 'new_claim.file.uploader.description.without_files',
  descriptionIdWithFiles: 'new_claim.file.uploader.description.with_files',
};

FileUploader.propTypes = {
  selectedFiles: PropTypes.arrayOf(
    PropTypes.shape({
      file: shape({ path: PropTypes.string }),
    })
  ),
  setSelectedFiles: PropTypes.func,
  descriptionIdWithoutFiles: PropTypes.string,
  descriptionIdWithFiles: PropTypes.string,
};
