import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import {
  BackButton,
  Input,
  FormSectionContainer,
  ButtonContainer,
  ErrorMessage,
  PageTitle,
  Text,
} from '../../../../components/common';
import { Button } from '../../../../components/ui';
import { invalidFillingCountRegex } from '../../../../shared/regex';
import {
  DENTOLO_FILLING_COUNT,
  useNewDentoloFormDataValue,
} from '../../claimFormData/newClaimFormData';
import { useFillingCountBackButtonLink, useFillingCountNextButtonLink } from './fillingCountHooks';

const maxValue = 100;

export default function FillingCount() {
  const { claimId } = useParams();

  const [fillingCount, setFillingCount] = useNewDentoloFormDataValue(DENTOLO_FILLING_COUNT);

  const backButtonLink = useFillingCountBackButtonLink(claimId);
  const nextButtonLink = useFillingCountNextButtonLink(claimId);

  const history = useHistory();

  // validation: `min: 1` `max: 100`
  const [isValidCount, setIsValidCount] = useState(true);

  const handleFillingCountKeyDown = (ev) => {
    if (!ev) return;

    const regex = new RegExp(invalidFillingCountRegex);

    if (regex.test(ev?.key)) {
      ev.preventDefault();
    }
  };

  const handleFillingCountBlur = (ev) => {
    if (!ev) return;

    // let value = ev?.target?.value?.replace('-', '');
    const value = ev?.target?.value;

    if (parseInt(value, 10) < 1 || Number.isNaN(Number(value)) || parseInt(value, 10) > maxValue) {
      setIsValidCount(false);
      setFillingCount('');
    } else if (value) {
      setIsValidCount(true);
      setFillingCount(value);
    }
  };

  const handleFillingCountChange = (ev) => {
    if (!ev) return;

    setIsValidCount(true);
    setFillingCount(ev?.target?.value);
  };

  const isFormValid = !!fillingCount && isValidCount;

  return (
    <>
      <BackButton to={backButtonLink}>
        <Typography variant="h3">
          <FormattedMessage id="common.button.back" />
        </Typography>
      </BackButton>

      <PageTitle>
        <Typography variant="h1">
          <FormattedMessage id="dentolo.new_claim.filling_count.page.title" />
        </Typography>
      </PageTitle>

      <FormSectionContainer>
        <Text style={{ marginBottom: '.5em' }}>
          <Typography variant="p">
            <FormattedMessage id="dentolo.new_claim.filling_count.form.label.filling_count" />
          </Typography>
        </Text>

        <Input
          type="number"
          name="filling_count"
          placeholder="2"
          value={fillingCount}
          onBlur={handleFillingCountBlur}
          onChange={handleFillingCountChange}
          onKeyDown={handleFillingCountKeyDown}
          data-testid="new-claim-filling-count-input"
        />

        {!isValidCount && (
          <ErrorMessage>
            <Typography variant="p">
              <FormattedMessage id="dentolo.new_claim.filling_count.form.error.invalid_filling_count" />
            </Typography>
          </ErrorMessage>
        )}
      </FormSectionContainer>

      <ButtonContainer>
        <Button
          onClick={() => history.push(nextButtonLink)}
          disabled={!isFormValid}
          data-testid="next-button"
        >
          <Typography variant="buttons">
            <FormattedMessage id="common.button.next" />
          </Typography>
        </Button>
      </ButtonContainer>
    </>
  );
}
