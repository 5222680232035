import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import JWTDecode from 'jwt-decode';
import { CSSTransition } from 'react-transition-group';
import queryString from 'query-string';
import { Typography } from '@mui/material';

import useInsuranceInfo from '../../hooks/useInsuranceInfo';
import {
  GET_CUSTOMER,
  CONFIRM_EMAIL,
  SET_CONFIRM_EMAIL_MUTATION,
  directlyLoadFromLocalStorage,
  useReady,
  INSURANCE_CATEGORY_PET_HEALTH,
  INSURANCE_CATEGORY_DENTAL,
  INSURANCE_CATEGORY_HEALTH,
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_TYPE_PETOLO,
  INSURANCE_TYPE_VITOLO,
} from '../../shared';

import { InfoCard, InputField, Paragraph } from '../ui';
import { Paper } from '../common';
import { TipIcon } from '../common/Icon';
import {
  AuthContainer,
  PageTitle,
  StyledRememberCheckbox,
  Button,
  StyledLoginTipContainer,
  StyledBoldTip,
} from './Login.styled';

const getButtonConfig = (insuranceType, intl) => {
  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return {
        text: (
          <Typography variant="p">
            {intl.formatMessage({ id: 'login.link.to_dentolo_homepage' })}
          </Typography>
        ),
        href: '//dentolo.de/',
      };
    case INSURANCE_TYPE_PETOLO:
      return {
        text: (
          <Typography variant="p">
            {intl.formatMessage({ id: 'login.link.to_petolo_homepage' })}
          </Typography>
        ),
        href: '//petolo.de/',
      };
    case INSURANCE_TYPE_VITOLO:
      return {
        text: (
          <Typography variant="p">
            {intl.formatMessage({ id: 'login.link.to_vitolo_homepage' })}
          </Typography>
        ),
        href: '//vitolo.de/',
      };
    default:
      return {
        text: (
          <Typography variant="p">
            {intl.formatMessage({ id: 'login.link.to_dentolo_homepage' })}
          </Typography>
        ),
        href: '//dentolo.de/',
      };
  }
};

const getInsuranceCategory = (insuranceType) => {
  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return INSURANCE_CATEGORY_DENTAL;
    case INSURANCE_TYPE_PETOLO:
      return INSURANCE_CATEGORY_PET_HEALTH;
    case INSURANCE_TYPE_VITOLO:
      return INSURANCE_CATEGORY_HEALTH;
    default:
      return INSURANCE_CATEGORY_DENTAL;
  }
};

const Login = () => {
  const [email, setEmail] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [disableForm, setDisableForm] = useState(false);
  const { type: insuranceType } = useInsuranceInfo();
  const location = useLocation();
  const history = useHistory();
  const ready = useReady();
  const intl = useIntl();

  const gduid = directlyLoadFromLocalStorage('gduid');
  const search = location?.search || '';
  const buttonConfig = getButtonConfig(insuranceType, intl);

  useEffect(() => {
    const params = queryString.parse(search);

    if (params?.logout === 'true' && !isLogout) {
      setIsLogout(true);
    }

    if (params?.invalid === 'true' && !isInvalid) {
      setIsInvalid(true);
      const jwt = params?.auth;
      const decodedJwt = jwt ? JWTDecode(jwt) : {};
      const decodedJwtEmail = decodedJwt?.email || '';
      setEmail(decodedJwtEmail);

      const rememberMeString = params?.remember_me;
      const isRememberMe = rememberMeString === 'true';
      setRememberMe(isRememberMe);
    }
  }, [isInvalid, isLogout, search]);

  const { data: customerData } = useQuery(GET_CUSTOMER);
  const customerUuid = customerData?.customer?.uuid;

  useEffect(() => {
    if (customerUuid) {
      history.push('/');
    }
  }, [customerUuid, history]);

  const [setConfirmEmail] = useMutation(SET_CONFIRM_EMAIL_MUTATION, {
    variables: {
      email,
    },
  });

  const [confirmEmail] = useMutation(CONFIRM_EMAIL, {
    variables: {
      email,
      gduid,
      rememberMe,
      insuranceCategory: getInsuranceCategory(insuranceType),
    },
  });

  const onFormSubmit = (e) => {
    e.preventDefault();
    setDisableForm(true);

    confirmEmail()
      .then(() => {
        const params = queryString.parse(location.search);
        const customerSourceZurich = params?.customer_source === 'zurich';
        const url = `/login/success${customerSourceZurich ? 'customer_source=zurich' : ''}`;

        setConfirmEmail().then(() => history.push(url));
        setDisableForm(false);
      })
      .catch();
  };
  const [showTip, setShowTip] = useState(false);
  return (
    <AuthContainer>
      <PageTitle>
        <Typography variant="h1">
          <FormattedMessage id="login.common.title" />
        </Typography>
      </PageTitle>

      <CSSTransition in={isLogout} timeout={400} classNames="fade" unmountOnExit>
        <InfoCard
          style={{ marginBottom: '1.5rem' }}
          mode="success"
          title={
            <Typography variant="h3">
              {intl.formatMessage({ id: 'login.info.message.logout_success' })}
            </Typography>
          }
          button={buttonConfig}
        />
      </CSSTransition>

      <CSSTransition in={isInvalid} timeout={400} classNames="fade" unmountOnExit>
        <InfoCard
          style={{ marginBottom: '1.5rem' }}
          mode="failure"
          title={
            <Typography variant="h3">
              {intl.formatMessage({ id: 'login.info.title.invalid_link' })}
            </Typography>
          }
          description={intl.formatMessage({ id: 'login.info.message.invalid_link' })}
        />
      </CSSTransition>

      <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
        <Paper style={{ padding: '2rem' }}>
          <form onSubmit={(e) => onFormSubmit(e)}>
            <fieldset disabled={disableForm} aria-busy={disableForm}>
              <InputField
                id="login-email"
                name="login-email"
                placeholder="maria.muster@web.de"
                labelText={
                  <Typography variant="h3">
                    {intl.formatMessage({ id: 'login.form.label' })}
                  </Typography>
                }
                labelStyle={{ fontWeight: 700 }}
                type="email"
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
                onFocus={() => setShowTip(true)}
              />

              <StyledRememberCheckbox
                id="login-checkbox"
                name="login-checkbox"
                label={
                  <Typography variant="p">
                    {intl.formatMessage({ id: 'login.form.check' })}
                  </Typography>
                }
                value={rememberMe}
                onChange={() => {
                  setRememberMe(!rememberMe);
                }}
              />

              {showTip && (
                // check typo  style for login tipp
                <StyledLoginTipContainer>
                  <TipIcon />
                  <Paragraph type="subtext" style={{ marginLeft: '0.5rem', display: 'flex' }}>
                    <StyledBoldTip>
                      {intl.formatMessage({ id: 'login.common.tip.title' })}
                    </StyledBoldTip>
                    {intl.formatMessage({ id: 'login.common.tip.description' })}
                  </Paragraph>
                </StyledLoginTipContainer>
              )}

              <Paragraph type="subtext" style={{ margin: '1.5rem 0' }}>
                <Typography variant="p">
                  {intl.formatMessage({ id: 'login.common.send.email' })}
                </Typography>
              </Paragraph>

              <Button type="submit" data-testid="btn_send_login_link">
                <Typography variant="p">
                  <FormattedMessage data-testid="btn_send_login_link" id="login.common.send.link" />
                </Typography>
              </Button>
            </fieldset>
          </form>
        </Paper>
      </CSSTransition>
    </AuthContainer>
  );
};

export default Login;
