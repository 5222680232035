import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import CheckIcon from './CheckIcon';
import {
  StyledPolicyDetailsSectionTitle,
  StyledPolicyDetailsList,
  StyledPolicyDetailsText,
} from '../styled/PolicyDetails.styled';

const AdditionalAdvantages = ({ details }) => {
  if (!Array.isArray(details)) return null;

  return (
    <>
      <StyledPolicyDetailsSectionTitle>
        <Typography variant="h3">
          <FormattedMessage id="policy.covergae_info.additional_advantages.title" />
        </Typography>
      </StyledPolicyDetailsSectionTitle>

      {details.map((item) => (
        <StyledPolicyDetailsList key={item}>
          <CheckIcon />

          <StyledPolicyDetailsText>
            <Typography variant="p">{item}</Typography>
          </StyledPolicyDetailsText>
        </StyledPolicyDetailsList>
      ))}
    </>
  );
};

export default AdditionalAdvantages;
