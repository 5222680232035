import { MIN_WIDTH_MD } from '../breakpoints';

export const muiTypographyComponent = {
  defaultProps: {
    variantMapping: {
      h1: 'h1',
      h2: 'h2',
      h3: 'h3',
      p: 'p',
      buttons: 'div',
    },
  },
  styleOverrides: {
    root: {
      wordBreak: 'break-word',
    },
  },
};

export const muiTypographyOptions = () => {
  return {
    fontSize: 16,
    fontFamily: 'Circular TT, Arial, sans-serif',
    h1: {
      fontSize: '1.5rem',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',

      [`@media (min-width: ${MIN_WIDTH_MD})`]: {
        fontSize: '1.875rem',
      },
    },
    h2: {
      fontSize: '1.25rem',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',

      [`@media (min-width: ${MIN_WIDTH_MD})`]: {
        fontSize: '1.5rem',
      },
    },

    h3: {
      fontSize: '1.125rem',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',

      [`@media (min-width: ${MIN_WIDTH_MD})`]: {
        fontSize: '1.25rem',
      },
    },

    p: {
      fontSize: '1.125rem',
      fontStyle: 'normal',
      fontWeight: 450,
      lineHeight: '1.5rem',

      [`@media (min-width: ${MIN_WIDTH_MD})`]: {
        fontSize: '1.25rem',
        lineHeight: '1.625rem',
      },
    },
    buttons: {
      fontSize: '1.25rem',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '1.5rem',

      [`@media (min-width: ${MIN_WIDTH_MD})`]: {
        fontSize: '1.5rem',
        lineHeight: '2rem',
      },
    },
  };
};
