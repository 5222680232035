import { css, styled } from '@mui/material';

import { MIN_WIDTH_LG } from '../../../theme/breakpoints';
import { Paper } from '../../common';

export const StyledClaimStatusInfoCard = styled(Paper)`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 1em;
  padding: 1em;
`;

export const StyledClaimStatusUpdatedTime = styled('div')(
  ({ theme }) => css`
    margin-bottom: 1em;
    color: ${theme.palette.main.professional_light_blue};
    opacity: 0.9;
  `
);

export const StyledClaimStatusTitle = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h5 {
    font-size: 1.25rem;
    line-height: 1.25rem;
  }

  @media (min-width: ${MIN_WIDTH_LG}) {
    h5 {
      line-height: 1.5rem;
    }
  }
`;

export const StyledClaimStatusDetails = styled('div')(
  ({ theme }) => css`
    margin-top: 1em;
    font-size: 1em;
    font-weight: 300;
    line-height: 1.25em;
    color: ${theme.palette.main.professional_light_blue};
    opacity: 0.9;
  `
);

export const StyledHelpCenterArticleContainer = styled('div')(
  ({ theme }) => css`
    background-color: ${theme.palette.background.info};
    padding: 1rem;
    margin-top: 1rem;
  `
);

export const StyledUserClaimComments = styled('p')(
  ({ theme }) => css`
    margin-top: 1em;
    font-size: 1em;
    font-weight: 300;
    line-height: 1.25em;
    border-radius: 2px;
    padding: 0.5em;
    opacity: 0.9;
    background-color: ${theme.palette.main.professional_light_blue};
  `
);

export const StyledUploadDocumentButtonContainer = styled('div')`
  display: flex;
  justify-content: center;
  margin-top: 1em;
  padding: 0 1em;
`;

export const StyledClaimDocumentsDownloadContainer = styled('div')(
  ({ theme }) => css`
    background: ${theme.palette.background.viewport};
    padding: 1rem;
    margin-top: 1rem;
  `
);

export const StyledClaimDocumentsDownloadTextTitle = styled('p')`
  font-size: 0.875rem;
  line-height: 1.125;
`;

export const StyledClaimDocumentsDownloadTextDescription = styled('p')`
  font-size: 1rem;
  line-height: 1.5;
`;
