import { css, styled } from '@mui/material';
import PropTypes from 'prop-types';

const StyledText = styled('p')(
  ({ theme, fontColor }) => css`
    color: ${fontColor || theme.palette.main.professional_blue};
  `
);

export default StyledText;

StyledText.propTypes = {
  fontColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};
