import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import { DocumentIcon, OpenLinkIcon } from '../../components/common/Icon';
import { formatDate } from '../../shared';
import { Button } from '../../components/ui';

import {
  StyledAlreadyUploadedDocument,
  StyledAlreadyUploadedDocumentContainer,
  StyledAlreadyUploadedDocumentDate,
  StyledAlreadyUploadedDocumentLinkContainer,
  StyledAlreadyUploadedDocumentLinkIconButton,
  StyledAlreadyUploadedDocumentsCard,
  StyledAlreadyUploadedDocumentsTitle,
} from './AlreadyUploadedDocuments.styled';

export default function AlreadyUploadedDocuments({ documentsAttachments }) {
  return (
    <StyledAlreadyUploadedDocumentsCard>
      <StyledAlreadyUploadedDocumentsTitle>
        <Typography variant="h3">
          <FormattedMessage id="user_claims_info.already_uploaded_documents.title" />
        </Typography>
      </StyledAlreadyUploadedDocumentsTitle>

      <div>
        {Array.isArray(documentsAttachments) &&
          documentsAttachments.map((document) => (
            <StyledAlreadyUploadedDocumentContainer key={document?.filename}>
              <StyledAlreadyUploadedDocument>
                <DocumentIcon />

                <div>
                  <Typography variant="p">{document?.filename}</Typography>
                  {document?.date && (
                    <StyledAlreadyUploadedDocumentDate>
                      <Typography variant="p">
                        <FormattedMessage
                          id="user_claims_info.already_uploaded_documents.document_date_label"
                          tagName="span"
                        />
                        &nbsp;
                        {formatDate(document?.date)}
                      </Typography>
                    </StyledAlreadyUploadedDocumentDate>
                  )}
                </div>
              </StyledAlreadyUploadedDocument>

              <StyledAlreadyUploadedDocumentLinkContainer>
                <Button
                  variant="nostyle"
                  onClick={() => (document?.url ? window.open(document?.url, '_blank') : null)}
                >
                  <Typography variant="p">
                    <FormattedMessage
                      id="user_claims_info.already_uploaded_documents.document_open_link"
                      tagName="h6"
                    />
                  </Typography>
                </Button>
                <StyledAlreadyUploadedDocumentLinkIconButton
                  variant="nostyle"
                  onClick={() => (document?.url ? window.open(document?.url, '_blank') : null)}
                >
                  <OpenLinkIcon />
                </StyledAlreadyUploadedDocumentLinkIconButton>
              </StyledAlreadyUploadedDocumentLinkContainer>
            </StyledAlreadyUploadedDocumentContainer>
          ))}
      </div>
    </StyledAlreadyUploadedDocumentsCard>
  );
}
