import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import Budget from './Budget';
import CheckIcon from './CheckIcon';
import {
  StyledPolicyDetailsSectionTitle,
  StyledPolicyDetailsList,
  StyledPolicyDetailsTextLg,
  StyledPolicyDetailsText,
} from '../styled/PolicyDetails.styled';

const Conditions = ({ conditions, budgetInfo, budgetAmountInfo, hasAddon }) => {
  if (!Array.isArray(conditions)) return null;

  return (
    <div style={{ margin: '1.5rem 0' }}>
      <StyledPolicyDetailsSectionTitle>
        <Typography variant="h3">
          <FormattedMessage id="policy.all_details.conditions.title" />:
        </Typography>
      </StyledPolicyDetailsSectionTitle>

      {conditions.map((item) => (
        <StyledPolicyDetailsList alignItems="flex-start" key={item?.title || item?.text}>
          <CheckIcon />

          <div>
            <StyledPolicyDetailsTextLg>
              <Typography variant="h3">{item?.title || ''}</Typography>
            </StyledPolicyDetailsTextLg>
            <StyledPolicyDetailsText>
              <Typography variant="p">{item?.text || ''}</Typography>
            </StyledPolicyDetailsText>
          </div>
        </StyledPolicyDetailsList>
      ))}

      <Budget budgetInfo={budgetInfo} budgetAmountInfo={budgetAmountInfo} hasAddon={hasAddon} />
    </div>
  );
};

export default Conditions;
