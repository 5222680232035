import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

import {
  BackButton,
  ButtonContainer,
  Checkbox,
  PageTitle,
  Radio,
} from '../../../../components/common';
import { Button } from '../../../../components/ui';
import {
  StyledRadioContainer,
  StyledRadioOptionContainer,
  StyledRadioLabel,
  StyledCheckboxContainer,
} from './SelectExistingClaim.styled';

export default function SelectExistingClaim(props) {
  const {
    name,
    backButtonLink,
    nextButtonLink,
    pageTitleId,
    claims,
    onSelectClaim,
    selectedClaimId,
    existingClaimNotSelectedKey,
    checkboxLabel,
  } = props;

  const history = useHistory();

  const handleCheckboxToggle = () => {
    let togglevalue = '';

    if (selectedClaimId !== existingClaimNotSelectedKey) {
      togglevalue = existingClaimNotSelectedKey;
    }

    onSelectClaim(togglevalue);
  };

  const claimsToList = Array.isArray(claims) ? claims : [];

  return (
    <>
      <BackButton to={backButtonLink}>
        <Typography variant="h3">
          <FormattedMessage id="common.button.back" />
        </Typography>
      </BackButton>

      <PageTitle>
        <Typography variant="h1">
          <FormattedMessage id={pageTitleId} />
        </Typography>
      </PageTitle>

      {claimsToList.map((claim) => {
        const { id, labelId, subLabel } = claim;

        return (
          <StyledRadioContainer key={id}>
            <StyledRadioOptionContainer onClick={() => onSelectClaim(id)}>
              <Radio
                name={name}
                value={id}
                checked={id === selectedClaimId}
                onChange={(e) => {
                  e.stopPropagation();
                  onSelectClaim(id);
                }}
              />

              <StyledRadioLabel>
                <Typography variant="h3">
                  <FormattedMessage id={labelId} />
                </Typography>
                <label htmlFor="claim-selector" fontSize="1rem" style={{ marginTop: '0.5rem' }}>
                  <Typography variant="p">{subLabel}</Typography>
                </label>
              </StyledRadioLabel>
            </StyledRadioOptionContainer>
          </StyledRadioContainer>
        );
      })}

      <StyledCheckboxContainer>
        <Checkbox
          type="checkbox"
          label={<Typography variant="p">{checkboxLabel}</Typography>}
          checked={selectedClaimId === existingClaimNotSelectedKey}
          onChange={handleCheckboxToggle}
        />
      </StyledCheckboxContainer>

      <ButtonContainer>
        <Button disabled={!selectedClaimId} onClick={() => history.push(nextButtonLink)}>
          <Typography variant="buttons">
            <FormattedMessage id="common.button.next" />
          </Typography>
        </Button>
      </ButtonContainer>
    </>
  );
}

SelectExistingClaim.propTypes = {
  name: PropTypes.string,
  backButtonLink: PropTypes.string,
  nextButtonLink: PropTypes.string,
  pageTitleId: PropTypes.string,
  onSelectClaim: PropTypes.func,
  selectedClaimId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  claims: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      labelId: PropTypes.string,
      subLabel: PropTypes.string,
    })
  ),
};
