import React from 'react';
import { Typography } from '@mui/material';

import CheckIcon from './CheckIcon';
import { Separator } from '../common';
import {
  StyledPolicyDetailsSectionTitle,
  StyledPolicyDetailsList,
  StyledPolicyDetailsTextLg,
  StyledPolicyDetailsText,
} from '../styled/PolicyDetails.styled';

const AdditionalAdvantagesPetolo = ({ details }) => {
  if (!Array.isArray(details)) return null;

  return (
    <>
      {details.map((item, index) => {
        const { title, subtitle, advantages } = item || {};

        return (
          <div key={title || subtitle}>
            <StyledPolicyDetailsSectionTitle>
              <Typography variant="h3">{title}</Typography>
            </StyledPolicyDetailsSectionTitle>

            {advantages?.map((advantage) => (
              <StyledPolicyDetailsList key={advantage?.text}>
                <CheckIcon />

                <StyledPolicyDetailsText>
                  <Typography variant="p">{advantage?.text}</Typography>
                </StyledPolicyDetailsText>
              </StyledPolicyDetailsList>
            ))}

            <StyledPolicyDetailsTextLg style={{ textAlign: 'right' }}>
              <Typography variant="h3">{subtitle}</Typography>
            </StyledPolicyDetailsTextLg>

            {index !== details.length - 1 && <Separator />}
          </div>
        );
      })}
    </>
  );
};

export default AdditionalAdvantagesPetolo;
