import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import { Typography } from '@mui/material';

import useInsuranceInfo from '../../hooks/useInsuranceInfo';
import { getInsuredPersonName } from '../../utils/customerUtils';
import {
  formatDate,
  formatPolicyName,
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_TYPE_PETOLO,
  contractInTrialMonth,
  showMoney,
} from '../../shared';

import PolicyAndAddonName from './PolicyAndAddonName';
import { Paper } from '../common';
import {
  StyledPolicyCardItem,
  StyledPolicyCardText,
  StyledPolicyCardTextThemed,
  StyledPolicyAmount,
} from '../styled/PolicyInfo.styled';

const MonthlyPremium = ({ customer, shouldFormatPolicyName, monthlyPremium, addons }) => {
  const intl = useIntl();

  if (!!addons && !!addons?.title) {
    const addOnTitle = addons?.title || '';
    const addOnPrice = addons?.price || 0;
    const premiumPrice = monthlyPremium - addOnPrice;
    const { policyName } = customer?.contract || {};
    const policyNameToUse = shouldFormatPolicyName
      ? intl.formatMessage({ id: `policies.${formatPolicyName(policyName)}` })
      : policyName;

    return (
      <>
        <StyledPolicyCardTextThemed>
          <Typography variant="p">
            <StyledPolicyAmount>{showMoney(premiumPrice)}</StyledPolicyAmount>
          </Typography>
          <span>
            <Typography variant="p">{policyNameToUse}</Typography>
          </span>
        </StyledPolicyCardTextThemed>

        <StyledPolicyCardTextThemed>
          <StyledPolicyAmount>
            <Typography variant="p">{showMoney(addOnPrice)}</Typography>
          </StyledPolicyAmount>
          <span>
            <Typography variant="p">{addOnTitle}</Typography>
          </span>
        </StyledPolicyCardTextThemed>
      </>
    );
  }

  return (
    <StyledPolicyCardTextThemed>
      <Typography variant="p">{showMoney(monthlyPremium)}</Typography>{' '}
    </StyledPolicyCardTextThemed>
  );
};

const PolicyCard = ({ customer, addons, contractStatusInfo }) => {
  const { type: insuranceType } = useInsuranceInfo();
  const isDentolo = insuranceType === INSURANCE_TYPE_DENTOLO;
  const isPetolo = insuranceType === INSURANCE_TYPE_PETOLO;

  const { date: contractDate, title: contractStatusTitle } = contractStatusInfo || {};
  const { name: petName } = customer?.insuredPet || {};

  const addonTrialMonth = customer?.contract?.addOnTrialMonth;
  const showTrialMonth =
    contractInTrialMonth(
      addonTrialMonth?.status,
      addonTrialMonth?.startingAt,
      customer?.contract?.startingAt
    ) ||
    (addonTrialMonth?.status === 'active' && dayjs(addonTrialMonth?.startingAt).isAfter(dayjs()));

  if (!customer?.contract) return null;

  return (
    <Paper hasShadow={false} filled style={{ marginBottom: '1rem' }}>
      <StyledPolicyCardItem type="title">
        <StyledPolicyCardText data-hj-suppress="true">
          <Typography variant="h3">{getInsuredPersonName(customer)}</Typography>
        </StyledPolicyCardText>

        <StyledPolicyCardTextThemed data-hj-suppress>
          <Typography variant="h3">{customer?.contract?.key}</Typography>
        </StyledPolicyCardTextThemed>
      </StyledPolicyCardItem>

      {isPetolo && (
        <StyledPolicyCardItem>
          <StyledPolicyCardText>
            <Typography variant="h3">
              <FormattedMessage id="policy.card.label.pet.name" />
            </Typography>
          </StyledPolicyCardText>

          <StyledPolicyCardTextThemed>
            <Typography variant="h3">{petName || ''}</Typography>
          </StyledPolicyCardTextThemed>
        </StyledPolicyCardItem>
      )}

      <StyledPolicyCardItem>
        <StyledPolicyCardText>
          <Typography variant="pـcolorless">
            <FormattedMessage id="policy.card.label.policy" />
          </Typography>
        </StyledPolicyCardText>

        <StyledPolicyCardTextThemed>
          <PolicyAndAddonName
            customer={customer}
            addons={addons}
            shouldFormatPolicyName={isDentolo}
          />
        </StyledPolicyCardTextThemed>
      </StyledPolicyCardItem>

      {!showTrialMonth && contractDate && contractStatusTitle && (
        <StyledPolicyCardItem>
          <StyledPolicyCardText>
            <Typography variant="p">{contractStatusTitle}</Typography>
          </StyledPolicyCardText>
          <StyledPolicyCardTextThemed>
            <Typography variant="p">{contractDate}</Typography>
          </StyledPolicyCardTextThemed>
        </StyledPolicyCardItem>
      )}

      {customer?.contract?.status !== 'ended' && (
        <StyledPolicyCardItem>
          <StyledPolicyCardText>
            <Typography variant="p">
              <FormattedMessage id="policy.card.label.monthly_premium" />
            </Typography>
          </StyledPolicyCardText>

          <MonthlyPremium
            customer={customer}
            shouldFormatPolicyName={isDentolo}
            monthlyPremium={customer?.contract?.monthlyPremium}
            addons={addons}
          />
        </StyledPolicyCardItem>
      )}

      {showTrialMonth && (
        <StyledPolicyCardItem>
          <StyledPolicyCardText>
            <FormattedMessage id="policy.card.label.addon.trial_month" />
          </StyledPolicyCardText>
          <StyledPolicyCardTextThemed>
            {formatDate(addonTrialMonth?.startingAt)}
          </StyledPolicyCardTextThemed>
        </StyledPolicyCardItem>
      )}
    </Paper>
  );
};

export default PolicyCard;

PolicyCard.propTypes = {
  customer: PropTypes.shape({
    contract: PropTypes.shape({
      key: PropTypes.string,
      monthlyPremium: PropTypes.string,
      policyName: PropTypes.string,
      startingAt: PropTypes.string,
      status: PropTypes.string,
      addOnTrialMonth: PropTypes.shape({
        status: PropTypes.string,
        startingAt: PropTypes.string,
      }),
    }),
    insuredPet: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  addons: PropTypes.shape({
    price: PropTypes.number,
    title: PropTypes.string,
  }),
  contractStatusInfo: PropTypes.shape({
    date: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  }),
};
