import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { styled, Typography } from '@mui/material';

import { DENTOLO_DENTOLO_DENTISTS_HELPCENTER_LINK, imgLinks } from '../../shared';
import { MIN_WIDTH_MD } from '../../theme/breakpoints';
import useLocalStorage from '../../hooks/useLocalStorage';

import { Paragraph } from '../ui';
import { Modal, Text } from '../common';

const StyledTitle = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.5rem;

  > img {
    width: 3.75rem;
    height: 3.75rem;
  }

  > p {
    margin-left: 0.5rem;
  }

  @media (min-width: ${MIN_WIDTH_MD}) {
    > img {
      width: 5rem;
      height: 5rem;
    }

    > p {
      margin-left: 1rem;
    }
  }
`;

const DentistsMapOnboard = ({ onboard, saveOnboardClose }) => {
  const [getFromLocalStorage] = useLocalStorage();

  const renderAnchorTag = (chunk) => {
    const gduidFromLocalStorage = getFromLocalStorage('gduid');
    const linkToUse = gduidFromLocalStorage
      ? `${DENTOLO_DENTOLO_DENTISTS_HELPCENTER_LINK}?gduid=${gduidFromLocalStorage}`
      : DENTOLO_DENTOLO_DENTISTS_HELPCENTER_LINK;
    return (
      <button
        type="button"
        onClick={(e) => {
          e.preventDefault();
          window.open(linkToUse, '_blank');
        }}
      >
        {chunk}
      </button>
    );
  };

  return (
    <Modal isOpen={onboard} onClose={saveOnboardClose} showClose>
      <StyledTitle>
        <img src={imgLinks['icon.female.dentist']} alt="female dentist" />

        <Text fontWeight="700">
          <Typography variant="h3">
            <FormattedMessage id="dentist.map.modal.title" />
          </Typography>
        </Text>
      </StyledTitle>

      <Paragraph>
        <Typography variant="p">
          <FormattedMessage id="dentist.map.modal.content_1" />
        </Typography>
      </Paragraph>

      <Paragraph>
        <Typography variant="p">
          <FormattedMessage
            id="dentist.map.modal.content_2"
            values={{
              button: (chunk) => renderAnchorTag(chunk),
            }}
          />
        </Typography>
      </Paragraph>
    </Modal>
  );
};

DentistsMapOnboard.propTypes = {
  onboard: PropTypes.bool,
  saveOnboardClose: PropTypes.func,
};

export default DentistsMapOnboard;
